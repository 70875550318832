var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ConvoFilter',{attrs:{"filter-path":'-',"filter-token":_vm.filterToken},on:{"appliedFilters":function($event){return _vm.appliedFilters($event)}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":"nameseniorcall"}},[_vm._v("Researcher")]),_c('v-select',{attrs:{"label":"name","options":_vm.users,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'users/fetchFakeUsers')}},model:{value:(_vm.filters.user),callback:function ($$v) {_vm.$set(_vm.filters, "user", $$v)},expression:"filters.user"}})],1),_c('hr'),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Document title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filters.title),expression:"filters.title"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.filters.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filters, "title", $event.target.value)}}})]),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Upload by")]),_c('v-select',{attrs:{"label":"name","options":[
          {name: 'All', value: ''},
          {name: 'ICREA', value: 'icrea'},
          {name: 'Host', value: 'host'} ],"get-option-key":function (option) { return option.value; }},model:{value:(_vm.filters.upload_by),callback:function ($$v) {_vm.$set(_vm.filters, "upload_by", $$v)},expression:"filters.upload_by"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Department")]),_c('v-select',{attrs:{"label":"name","options":_vm.departments,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'institutions/fetchDepartments')}},model:{value:(_vm.filters.department),callback:function ($$v) {_vm.$set(_vm.filters, "department", $$v)},expression:"filters.department"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Workplaces")]),_c('v-select',{attrs:{"label":"name","options":_vm.workplaces,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'workplaces/filter')}},model:{value:(_vm.filters.workplace),callback:function ($$v) {_vm.$set(_vm.filters, "workplace", $$v)},expression:"filters.workplace"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Hazards")]),_c('v-select',{attrs:{"label":"name","options":_vm.hazards,"get-option-key":function (option) { return option.id; }},on:{"search":function($event){return _vm.onSearch({ name: $event }, 'hazards/filter')}},model:{value:(_vm.filters.hazard),callback:function ($$v) {_vm.$set(_vm.filters, "hazard", $$v)},expression:"filters.hazard"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("New doc")]),_c('v-select',{attrs:{"label":"name","options":[
          {name: 'All', value: null},
          {name: 'Yes', value: true},
          {name: 'No', value: false} ],"get-option-key":function (option) { return option.value; }},model:{value:(_vm.filters.new_doc),callback:function ($$v) {_vm.$set(_vm.filters, "new_doc", $$v)},expression:"filters.new_doc"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Renew")]),_c('v-select',{attrs:{"label":"name","options":[
          {name: 'All years', value: null},
          {name: '2026', value: 2026},
          {name: '2025', value: 2025},
          {name: '2024', value: 2024},
          {name: '2023', value: 2023},
          {name: '2022', value: 2022},
          {name: '2021', value: 2021},
          {name: '2020', value: 2020} ],"get-option-key":function (option) { return option.value; }},model:{value:(_vm.filters.renew_year),callback:function ($$v) {_vm.$set(_vm.filters, "renew_year", $$v)},expression:"filters.renew_year"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Status")]),_c('v-select',{attrs:{"label":"name","options":[
          {name: 'All', value: null},
          {name: 'Active', value: 'active'},
          {name: 'Archived', value: 'archived'} ],"get-option-key":function (option) { return option.value; }},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c('hr'),_c('h5',[_vm._v("Upload")]),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("From")]),_c('date-picker',{staticClass:"w-100",model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("To ")]),_c('date-picker',{staticClass:"w-100",model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }