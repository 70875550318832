<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ section.name }}<br>
                <small v-if="institution"><span class="badge bg-light-primary dis-ib">{{ institution.name }}</span></small> <!-- solo admin -->
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
              @click="$router.go(-1)"
            ><i data-feather="chevron-left" /></a>
            <span
              class="dis-ib ms-50"
              title="Search documents"
              @click="openFilters"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="search" /></a>
            </span>
            <span
              class="dis-ib ms-50"
              title="Export"
              @click="$store.dispatch('modals/toggleCaeExport', true)"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="file" /></a>
            </span>
            <a
              v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')"
              class="btn btn-icon btn-primary ms-50"
              @click="$router.push({name: 'cae.labour-hazards.create', query: { section: section.id, parent: (section.parent ? section.parent.id : null), host: filters.host.id, actAs: $route.query.actAs }})"
            ><i data-feather="upload" /></a>
          </div>
        </div>
      </div>
      <hr>
      <div
        class="content-body content-body--senior-call"
      >
        <template v-if="section.subsections && section.subsections.length != 0">
          <h3 class="mb-2">
            {{ section.subsections.length }} subsections
          </h3>

          <div class="row row--cae--card align-items-stretch">
            <div
              v-for="subsection in section.subsections"
              :key="subsection.id"
              class="col-sm-4 row-cae-card--container"
            >
              <div class="card">
                <div class="card-body text-center d-flex align-items-center flex-column">
                  <div class="card--cae--content mb-auto">
                    <h3>{{ subsection.name }}</h3>
                    <p class="text-muted">
                      {{ subsection.documents }} documents
                    </p>
                  </div>
                  <a
                    class="btn btn-small btn-primary"
                    @click="$router.push({name:'cae.view', params: {id: subsection.id}, query: {host: filters.host.id, actAs: $route.query.actAs}})"
                  >View documents</a>
                </div>
              </div>
            </div>
          </div>
        </template>
        <hr v-if="section.subsections && section.subsections.length != 0">
        <h3 class="mb-2">
          {{ active.length }} active documents
        </h3>

        <div class="row">
          <div class="col-12">

            <div class="row row--cae--card align-items-stretch">
              <div
                v-for="docum in active"
                :key="docum.id"
                class="col-sm-4 row-cae-card--container"
              >
                <div class="card">
                  <div class="card-body text-center d-flex align-items-center flex-column">
                    <div class="card--cae--content mb-auto">
                      <p class="text-muted">
                        {{ docum.section.name }}
                      </p>
                      <h3>{{ docum.title }}</h3>
                      <!-- <template
                        v-if="docum.departments"
                      >
                        <span class="badge bg-light-primary">{{ docum.departments }}</span>
                      </template> -->
                      <span
                        class="text-truncate"
                        v-html="docum.description"
                      />
                    </div>
                    <a
                      class="btn btn-small btn-primary"
                      @click="$router.push({name: 'cae.view-document', params: {id: docum.id}, query: {host: filters.host.id, actAs: $route.query.actAs}})"
                    >View document</a>
                    <p class="mt-1">
                      <small>Upload: {{ docum.uploaded }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <CompleteTable
          :sorted-field="sortedField"
          :data="archived"
          :sort-mapping="sortMapping"
          :title="'archived files'"
          :total="archived.length"
          :fetch-path="''"
          :export-path="''"
          :filter-path="''"
          :hide-bottom="true"
        >
          <template v-slot="{field}">
            <td
              class="text-end"
            >
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <i data-feather="more-vertical" />
                </template>
                <div
                  class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                >
                  <b-dropdown-item @click="$router.push({name: 'cae.view-document', params: {id: field.id}, query: {host: filters.host.id, actAs: $route.query.actAs}})">
                    <i data-feather="eye" />
                    View document
                  </b-dropdown-item>
                  <b-dropdown-item
                    :href="field.url"
                    target="_blank"
                  >
                    <i data-feather="download" />
                    Download document
                  </b-dropdown-item>
                  <!-- <hr>
                  <b-dropdown-item @click="showDeleteModal(field.id)">
                    <i data-feather="trash-2" />
                    Delete
                  </b-dropdown-item> -->
                </div>
              </b-dropdown>
            </td>
          </template>
        </CompleteTable>
        <Export />
        <Search />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../partials/components/CompleteTable.vue'
import Export from './partials/Export.vue'
import Search from './partials/Search.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    Export,
    Search,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Section',
          checked: true,
          order: 2,
        },
        {
          name: 'Subsection',
          checked: true,
          order: 3,
        },
        {
          name: 'Department',
          checked: true,
          order: 4,
        },
        {
          name: 'Upload date',
          checked: true,
          order: 5,
        },

        {
          name: 'Deadline',
          checked: true,
          order: 6,
        },
        {
          name: 'Status',
          checked: true,
          order: 7,
        },
      ],
      sortMapping: {
        Name: 'title',
        Section: 'section_table.name',
        Subsection: 'subsection_table.name',
        Department: 'departments',
        'Upload date': 'uploaded',
        Deadline: 'deadline',
        Status: 'status',
      },
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      section: 'cae/item',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
      institution: 'cae/institution',
    }),
    archived() {
      return this.section && this.section.documents ? this.section.documents.filter(e => e.active !== true) : []
    },
    active() {
      return this.section && this.section.documents ? this.section.documents.filter(e => e.active === true) : []
    },
    id() {
      return this.$route.params.id
    },
  },
  watch: {
    id() {
      this.$store.dispatch('modals/fetchUserFields', 'archived_files')
      this.$store.dispatch('cae/fetchId', { id: this.id, filters: this.filters })
    },
  },
  async mounted() {
    let institution = null
    if (!this.institution) {
      institution = { id: this.$route.query.host }
    } else {
      institution = this.institution
    }

    if (!institution) {
      this.$router.push({ name: 'cae.index' })
    }

    this.$store.dispatch('filters/saveFilters', {
      value: institution,
      field: 'host',
    })
    await this.$store.dispatch('modals/fetchUserFields', 'archived_files')
    await this.$store.dispatch('cae/fetchId', { id: this.id, filters: this.filters })

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'archived_files',
      })
    }
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
  },
}
</script>
